import { Injectable, Injector } from '@angular/core';

import { BaseService } from 'iaudit-core-library';

import { IBehaviorProfile } from '../model/model';
import { UrlService } from '../../../service/url.service';
import { ApplicationCookieUserService } from '../../../service/application-cookie-user.service';

@Injectable({
    providedIn: 'root'
})
export class BehaviorProfileService extends BaseService {
    constructor(
        inject: Injector,
        private urlService: UrlService,
        private applicationCookieUserService: ApplicationCookieUserService
    ) {
        super(inject);
        super.setCookieUser(applicationCookieUserService.getCookieName());
    }

    private init(): IBehaviorProfile {
        const claimsValue: IBehaviorProfile = {
            ClientUser: false,
            ClientMaster: false
        };
        return claimsValue;
    }

    private createInstance(base: IBehaviorProfile = null): IBehaviorProfile {
        const behaviorProfile: IBehaviorProfile = {
            ClientUser: base == null ? false : base.ClientUser,
            ClientMaster: base == null ? false : base.ClientMaster
        };
        return behaviorProfile;
    }

    get(): IBehaviorProfile {
        const claims = this.applicationCookieUserService.getUserInfo().Claims;
        const claimValue = this.init();
        const filter = claims.filter(res => res.Type === 'BehaviorProfile' && res.ApplicationId === this.urlService.getApplicationId());

        filter.forEach(claim => {
            switch (claim.Value) {
                case 'ClientUser':
                    claimValue.ClientUser = true;
                    break;
                case 'ClientMaster':
                    claimValue.ClientMaster = true;
                    break;
                default:
                    break;
            }
        });

        return this.createInstance(claimValue);
    }
}

import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";

import { BaseService, DataValueResponse } from "iaudit-core-library";

import { HandleService } from "src/app/02 - Domain/shared/service/handle.service";
import {
  ChooseProfileType,
  DateFormatHourType,
  OriginType,
  PersonHistoricalType,
  SearchByPeriodType,
} from "src/app/02 - Domain/shared/types/types";
import { ILanguageResponse } from "../../language/model/model";
import { TicketAnalysisStatusType, TicketStatusType } from "../types/types";
import { AuditType, ResultType } from "./../types/types";
import { ApplicationCookieUserService } from "./application-cookie-user.service";

@Injectable({
  providedIn: "root",
})
export class UtilService extends BaseService {
  upgradeTime: number;
  interval: any;
  constructor(
    inject: Injector,
    applicationCookieUserService: ApplicationCookieUserService,
    private router: Router,
    private handleService: HandleService
  ) {
    super(inject);
    super.setCookieUser(applicationCookieUserService.getCookieName());
  }

  getStatusTicket(): any {
    const result = new Array();
    result.push(
      new DataValueResponse(
        TicketStatusType.Open,
        this.translate("context.ticket.status.options.open")
      )
    );
    result.push(
      new DataValueResponse(
        TicketStatusType.Treat,
        this.translate("context.ticket.status.options.treat")
      )
    );
    result.push(
      new DataValueResponse(
        TicketStatusType.Closed,
        this.translate("context.ticket.status.options.closed")
      )
    );
    result.push(
      new DataValueResponse(
        AuditType.Reanalyze,
        this.translate("context.ticket.create.audit.options.reanalyze")
      )
    );
    result.push(
      new DataValueResponse(
        TicketStatusType.ReturnAppellant,
        this.translate("context.ticket.create.status.options.returnAppellant")
      )
    );
    return result;
  }

  getStage(createDefaulNull = true): any {
    const result = new Array();
    result.push(
      new DataValueResponse(
        1,
        this.translate("context.ticket.status.options.open")
      )
    );
    result.push(
      new DataValueResponse(
        2,
        this.translate("context.ticket.status.options.treat")
      )
    );
    result.push(
      new DataValueResponse(
        3,
        this.translate("context.ticket.status.options.closed")
      )
    );
    result.push(
      new DataValueResponse(
        4,
        this.translate("context.ticket.status.options.analysis")
      )
    );
    result.push(
      new DataValueResponse(
        5,
        this.translate("context.ticket.status.options.returnAppellant")
      )
    );
    result.push(
      new DataValueResponse(
        6,
        this.translate("context.ticket.status.options.review")
      )
    );
    return result;
  }

  getTicketStatus(): DataValueResponse[] {
    const result = new Array<DataValueResponse>();
    result.push(
      new DataValueResponse(
        TicketStatusType.Open,
        this.translate("context.ticket.status.options.open")
      )
    );
    result.push(
      new DataValueResponse(
        TicketStatusType.Treat,
        this.translate("context.ticket.status.options.treat")
      )
    );
    result.push(
      new DataValueResponse(
        TicketStatusType.Closed,
        this.translate("context.ticket.status.options.closed")
      )
    );
    result.push(
      new DataValueResponse(
        AuditType.Reanalyze,
        this.translate("context.ticket.status.options.reanalyze")
      )
    );
    result.push(
      new DataValueResponse(
        TicketStatusType.ReturnAppellant,
        this.translate("context.ticket.status.options.returnAppellant")
      )
    );
    result.push(
      new DataValueResponse(
        TicketStatusType.Review,
        this.translate("context.ticket.status.options.review")
      )
    );
    result.push(
      new DataValueResponse(
        TicketStatusType.WaitingBgc,
        this.translate("context.ticket.status.options.waitingBgc")
      )
    );

    result.push(
      new DataValueResponse(
        TicketStatusType.AutomaticClosing,
        this.translate("data.ticketStatusType.automaticClosing")
      )
    );
    result.push(
      new DataValueResponse(
        TicketStatusType.TreatWithDocument,
        this.translate("data.ticketStatusType.treat-with-document")
      )
    );
    return result;
  }

  getStageAnalysis(): any {
    const result = new Array();
    result.push(
      new DataValueResponse(
        TicketAnalysisStatusType.ReturnAppelant,
        this.translate("context.ticket.create.status.options.returnAppellant")
      )
    );
    result.push(
      new DataValueResponse(
        TicketAnalysisStatusType.Closed,
        this.translate("context.ticket.create.status.options.closed")
      )
    );
    return result;
  }

  getOrigin(): DataValueResponse[] {
    const result = new Array<DataValueResponse>();
    result.push(
      new DataValueResponse(
        OriginType.Drive,
        this.translate("context.origin.driver")
      )
    );
    result.push(
      new DataValueResponse(
        OriginType.Customer,
        this.translate("context.origin.uber")
      )
    );
    return result;
  }

  getMonths(): string[] {
    return [
      this.translate("context.months.january.abbreviation"),
      this.translate("context.months.february.abbreviation"),
      this.translate("context.months.march.abbreviation"),
      this.translate("context.months.april.abbreviation"),
      this.translate("context.months.may.abbreviation"),
      this.translate("context.months.june.abbreviation"),
      this.translate("context.months.july.abbreviation"),
      this.translate("context.months.august.abbreviation"),
      this.translate("context.months.september.abbreviation"),
      this.translate("context.months.october.abbreviation"),
      this.translate("context.months.november.abbreviation"),
      this.translate("context.months.december.abbreviation"),
    ];
  }

  getPersonHistorical(): any {
    const result = new Array();
    result.push(
      new DataValueResponse(
        PersonHistoricalType.UnBlockage,
        this.translate("context.consultHistorical.table.actions.unBlockage")
      )
    );
    result.push(
      new DataValueResponse(
        PersonHistoricalType.Blockage,
        this.translate("context.consultHistorical.table.actions.blockage")
      )
    );
    result.push(
      new DataValueResponse(
        PersonHistoricalType.Reopen,
        this.translate("context.consultHistorical.table.actions.reopen")
      )
    );
    result.push(
      new DataValueResponse(
        PersonHistoricalType.Prospect,
        this.translate("context.consultHistorical.table.actions.prospect")
      )
    );
    result.push(
      new DataValueResponse(
        PersonHistoricalType.Passed,
        this.translate("context.consultHistorical.table.actions.passed")
      )
    );
    result.push(
      new DataValueResponse(
        PersonHistoricalType.Metadata,
        this.translate("context.consultHistorical.table.actions.metadata")
      )
    );
    result.push(
      new DataValueResponse(
        PersonHistoricalType.Open,
        this.translate("context.consultHistorical.table.actions.open")
      )
    );
    result.push(
      new DataValueResponse(
        PersonHistoricalType.Closed,
        this.translate("context.consultHistorical.table.actions.close")
      )
    );
    return result;
  }

  getIndicatorsPeriod(): DataValueResponse[] {
    const result = new Array();
    result.push(
      new DataValueResponse(
        SearchByPeriodType.LastDay,
        this.translate("context.dashboard.lastDay")
      )
    );
    result.push(
      new DataValueResponse(
        SearchByPeriodType.Today,
        this.translate("context.dashboard.today")
      )
    );
    result.push(
      new DataValueResponse(
        SearchByPeriodType.Last7Days,
        this.translate("context.dashboard.last7Days")
      )
    );
    result.push(
      new DataValueResponse(
        SearchByPeriodType.Last15Days,
        this.translate("context.dashboard.last15Days")
      )
    );
    result.push(
      new DataValueResponse(
        SearchByPeriodType.Last30Days,
        this.translate("context.dashboard.last30Days")
      )
    );
    return result;
  }

  getYesNo(): any {
    const result = new Array();
    result.push(new DataValueResponse(false, this.translate("data.no")));
    result.push(new DataValueResponse(true, this.translate("data.yes")));
    return result;
  }

  getContinueTicket(): any {
    const result = new Array();
    result.push(
      new DataValueResponse(
        true,
        this.translate("context.createTicket.continueTicket.portal")
      )
    );
    result.push(
      new DataValueResponse(
        false,
        this.translate("context.createTicket.continueTicket.customer")
      )
    );
    return result;
  }

  getStageResult(): any {
    const result = new Array();
    result.push(
      new DataValueResponse(
        ResultType.Proceeding,
        this.translate("context.result.options.proceeding")
      )
    );
    result.push(
      new DataValueResponse(
        ResultType.Unfounded,
        this.translate("context.result.options.unfounded")
      )
    );
    result.push(
      new DataValueResponse(
        ResultType.NotAgree,
        this.translate("context.result.options.notAgree")
      )
    );
    result.push(
      new DataValueResponse(
        ResultType.IncorrectDocument,
        this.translate("context.result.options.incorrectDocument")
      )
    );
    return result;
  }

  getStageAudit(): any {
    const result = new Array();
    result.push(
      new DataValueResponse(
        AuditType.Reanalyze,
        this.translate("context.ticket.create.audit.options.reanalyze")
      )
    );
    result.push(
      new DataValueResponse(
        AuditType.Closed,
        this.translate("context.ticket.create.audit.options.closed")
      )
    );
    return result;
  }

  getSearchByType(): any {
    const result = new Array();
    result.push(
      new DataValueResponse(
        ChooseProfileType.Analyst,
        this.translate("context.billingReport.analyst")
      )
    );
    result.push(
      new DataValueResponse(
        ChooseProfileType.Audit,
        this.translate("context.billingReport.audit")
      )
    );
    return result;
  }

  getProfile(): any {
    const result = new Array();
    result.push(
      new DataValueResponse(
        ChooseProfileType.Analyst,
        this.translate("context.chooseProfile.analyst")
      )
    );
    result.push(
      new DataValueResponse(
        ChooseProfileType.Audit,
        this.translate("context.chooseProfile.audit")
      )
    );
    return result;
  }

  clearTimer(): void {
    this.upgradeTime = 0;
    clearInterval(this.interval);
  }

  pad(n: any): any {
    return n < 10 ? "0" + n : n;
  }

  formatCPF(cpf: string): string {
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  getDateFormatted(
    date: moment.Moment,
    dateFormatHour: DateFormatHourType
  ): string {
    return dateFormatHour == DateFormatHourType.StartDay
      ? date
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format("YYYY-MM-DDTHH:mm:ss") + ".000Z"
      : date
          .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
          .format("YYYY-MM-DDTHH:mm:ss") + ".000Z";
  }

  getApplicationLanguages(
    languageResponse: ILanguageResponse[]
  ): ILanguageResponse[] {
    const languages = languageResponse.filter(
      (src) => src.Code === "pt" || src.Code === "en"
    );
    return languages;
  }
}

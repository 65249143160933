import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor() { }

  getCustomerApiUrl(): string {
    return environment.CustomerApiUrl;
  }

  getApelacaoApiUrl(): string {
    return environment.ApelacaoApiUrl;
  }

  getLanguageApiUrl(): string {
    return environment.LanguageApiUrl;
  }
  
  getApplicationId(): string {
    return environment.ApplicationId;
  }

  getAuthenticationApiUrl(): string {
    return environment.AuthenticationApiUrl;
  }

}

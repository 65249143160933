import { BaseService } from 'iaudit-core-library';
import { Injectable, Injector } from '@angular/core';
import { ITicketClaim } from '../model/model';
import { ApplicationCookieUserService } from '../../shared/service/application-cookie-user.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimTicketService extends BaseService {
  constructor(
    inject: Injector,
    private applicationCookieUserService: ApplicationCookieUserService
  ) {
    super(inject);
    super.setCookieUser(applicationCookieUserService.getCookieName());
  }

  private init(): ITicketClaim {
    const claimsValue: ITicketClaim = {
      GetByDocumentExternal: false,

    };
    return claimsValue;
  }

  private createInstance(base: ITicketClaim = null): ITicketClaim {
    const behaviorClaimResult: ITicketClaim = {
      GetByDocumentExternal: base == null ? false : base.GetByDocumentExternal
    };
    return behaviorClaimResult;
  }

  get(): ITicketClaim {
    const claims = this.applicationCookieUserService.getUserInfo().Claims;
    const filter = claims.filter(res => res.Type === 'Ticket');
    if (filter.length === 0) {
      return this.createInstance();
    }
    const claimValue = this.init();

    filter.forEach(claim => {
      switch (claim.Value) {
        case 'GetByDocumentExternal':
          claimValue.GetByDocumentExternal = true;
          break;
      }
    });
    const resultClaim = this.createInstance(claimValue);
    return resultClaim;

  }

}
import { AfterViewChecked, Component, Injector, OnInit } from "@angular/core";
import { UtilService } from "src/app/02 - Domain/shared/service/util.service";

import {
  BaseComponent,
  DataValueResponse,
  SingleSignOnService,
} from "iaudit-core-library";

import { LanguageService } from "./02 - Domain/language/service/language.service";
import { ApplicationCookieUserService } from "./02 - Domain/shared/service/application-cookie-user.service";
import { HandleService } from "./02 - Domain/shared/service/handle.service";
import { SingleSignOnEnvironmentService } from "./02 - Domain/shared/service/single-sign-on-environment.service";

declare var loadScript: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent
  extends BaseComponent
  implements OnInit, AfterViewChecked
{
  title = "manager-client";
  isload = false;

  constructor(
    injector: Injector,
    private languageService: LanguageService,
    private handleService: HandleService,
    private singleSignOnService: SingleSignOnService,
    private singleSignOnEnvironmentService: SingleSignOnEnvironmentService,
    private utilService: UtilService,
    private applicationCookieUserService: ApplicationCookieUserService
  ) {
    super(injector);
    super.setCookieUser(applicationCookieUserService.getCookieName());

    const config = this.singleSignOnEnvironmentService.GetConfig();
    this.singleSignOnService.setup(config);

    this.applicationService.translateEmmitter.subscribe(
      (response: DataValueResponse) => {
        if (response !== undefined && response !== null) {
          this.customTranslateService.use(response.Code);
        }
        return;
      }
    );

    this.handleService.logoutEmmiter.subscribe(() => {
      this.logout();
    });
  }

  private logout(): void {
    this.applicationCookieUserService.delete();
    this.router.navigateByUrl("auth/login");
  }

  private initialize(): void {
    this.spinner.show();
    this.languageService.get().subscribe(
      (response) => {
        const languages = this.utilService.getApplicationLanguages(response);
        if (languages) {
          this.cookieLanguageService.create(languages);
          this.customTranslateService.setup(languages.map(({ Code }) => Code));
          this.handleService.notifyLanguageTranslate();
        }
      },
      (fail) => {},
      () => {
        this.spinner.hide();
        this.handleService.notifyLanguageTranslate();
      }
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initialize();
  }

  ngAfterViewChecked(): void {
    if (!this.isload) {
      this.isload = true;
    }
    loadScript();
  }
}

import { Component, Injector, OnInit } from "@angular/core";

import { BaseComponent } from "iaudit-core-library";

import { ILanguageResponse } from "src/app/02 - Domain/language/model/model";
import { LanguageService } from "src/app/02 - Domain/language/service/language.service";
import { ApplicationCookieUserService } from "src/app/02 - Domain/shared/service/application-cookie-user.service";
import { HandleService } from "src/app/02 - Domain/shared/service/handle.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  user: any;
  languages = new Array<ILanguageResponse>();

  constructor(
    injector: Injector,
    private languageService: LanguageService,
    private handleService: HandleService,
    private applicationCookieUserservice: ApplicationCookieUserService
  ) {
    super(injector);
  }

  private onChangeLocallyLanguageFlag(
    languageResponse: ILanguageResponse[]
  ): ILanguageResponse[] {
    languageResponse.forEach((x) => {
      if (x.Code === 'pt') {
        x.Flag = '/assets/iaudit/img/flags/pt.png';
      }
      if (x.Code === 'en') {
        x.Flag = '/assets/iaudit/img/flags/en.png';
      }
      if (x.Code === 'es') {
        x.Flag = '/assets/iaudit/img/flags/es.png';
      }
    });
    return languageResponse;
  }

  ngOnInit(): void {
    this.user = this.applicationCookieUserservice.getUserInfo();
    this.languageService.get().subscribe((response) => {
      this.languages = this.onChangeLocallyLanguageFlag(response);
    });
  }

  logout(): void {
    this.handleService.notifyLogout();
  }

  onChangeLanguage(codeLanguage: string) {
    this.customTranslateService.use(codeLanguage);
  }
}

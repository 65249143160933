import { Injectable, Injector } from '@angular/core';

import { BaseService } from 'iaudit-core-library';

import { IPersonValidateClaim } from '../model/model';
import { ApplicationCookieUserService } from '../../shared/service/application-cookie-user.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimPersonValidateService extends BaseService {
  constructor(
    inject: Injector,
    private applicationCookieUserService: ApplicationCookieUserService
  ) {
    super(inject);
    super.setCookieUser(applicationCookieUserService.getCookieName());
  }

  private init(): IPersonValidateClaim {
    const claimsValue: IPersonValidateClaim = {
      Exists: false,

    };
    return claimsValue;
  }

  private createInstance(base: IPersonValidateClaim = null): IPersonValidateClaim {
    const behaviorClaimResult: IPersonValidateClaim = {
      Exists: base == null ? false : base.Exists
    };
    return behaviorClaimResult;
  }

  get(): IPersonValidateClaim {
    const claims = this.applicationCookieUserService.getUserInfo().Claims;
    const filter = claims.filter(res => res.Type === 'PersonValidate');
    if (filter.length === 0) {
      return this.createInstance();
    }
    const claimValue = this.init();

    filter.forEach(claim => {
      switch (claim.Value) {
        case 'Exists':
          claimValue.Exists = true;
          break;
      }
    });
    const resultClaim = this.createInstance(claimValue);
    return resultClaim;

  }

}
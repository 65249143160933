export enum OriginType {
  Drive = 1,
  Internal,
  Customer,
}

export enum ResultType {
  Proceeding = 1,
  Unfounded,
  NotAgree,
  NewBgc,
  IncorrectDocument,
}

export enum MessageAutomaticClosedType {
  first = 1,
  second,
  third,
}

export enum TicketStatusType {
  Open = 1,
  Treat,
  Closed,
  Reanalyze,
  ReturnAppellant,
  Review,
  WaitingBgc,
  AutomaticClosing,
  TreatWithDocument,
}

export enum TicketAnalysisStatusType {
  ReturnAppelant = 1,
  NewBgc,
  Closed,
}

export enum ReopenType {
  ClosedAutomatic = 1,
  IncorrectDocument,
}

export enum AuditType {
  Closed = 3,
  Reanalyze,
}

export enum PersonValidateType {
  // PersonExists = 1,
  // ShowQuestions,
  // NotFound,
  // Undefined,
  // SamBgc,
  // Create
  Passed,
  Failed,
}

export enum BgcErrorType {
  BgcNotFound = 1,
  BgcNotFailed,
  TicketBlocked,
}

export enum ChooseProfileType {
  Analyst = 1,
  Audit,
}

export enum BgcStatusType {
  Passed = 1,
  Failed,
  Other,
}

export enum ErrorTypeNewTicket {
  NotWaitingBgc = 1,
  BgcNotFound,
  BgcNotFailed,
  BgcEgual,
}

export enum BgcStatus {
  Passed = 1,
  Failed,
  Other,
}

export enum SearchTicketByType {
  Drive = 1,
  Customer,
}

export enum ContinueTicket {
  Portal = 1,
  Customer,
}

export enum PersonHistoricalType {
  UnBlockage,
  Blockage,
  Reopen,
  Prospect,
  Passed,
  Metadata,
  Open,
  Closed,
}

export enum MonthType {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export enum dashboardOvertimeType {
  new = 1,
  closed
}

export enum PortalSyntheticType {
  WithoutBgc,
  LastBgcPassed,
  InProcessBgc,
  QuestionError,
  Treating,
  Closed,
  ReOpenByCustomer,
  InitialAnalysis,
  ReturnAppellant,
  SendedByDriver,
  WaitingBgc,
  Proceeding,
  Unfounded,
  IncorrectDocument,
  AutomaticClose
}

export enum DashboardIndicatorGeneralType {
  WithoutBgc,
  LastBgcPassed,
  ProcessBgc,
  QuestionError,
  Treating,
  Closed,
  ReOpenByCustomer,
}

export enum DashboardIndicatorInProgressType {
  Initial,
  WaitingDocument,
  SentByDriver,
  AwaitingBGC,
}

export enum DashboardIndicatorClosedType {
  Proceeding,
  UnfoundedByCriminalRecord,
  UnfoundedByDocument,
  UnfoundedNonSubmissionDocuments,
}

export enum SearchByPeriodType {
  Today = 0,
  LastDay = -1,
  Last7Days = -7,
  Last15Days = -15,
  Last30Days = -30,
}

export enum DateFormatHourType {
  StartDay = 1,
  EndDay
}

export enum ConsultType {
  Document = 1,
  Uuid
}


import { EventEmitter, Injectable, Injector } from '@angular/core';

import { BaseService } from 'iaudit-core-library';
import { ApplicationCookieUserService } from './application-cookie-user.service';

export enum finishSetupType {
  finished = 0,
  authentication,
  translate,
}

@Injectable({
  providedIn: 'root'
})
export class HandleService extends BaseService {
  public languageTranslateEmmiter: EventEmitter<any> = new EventEmitter();
  public logoutEmmiter: EventEmitter<any> = new EventEmitter(null);
  public updateConsultEmmiter: EventEmitter<any> = new EventEmitter(null);
  
  constructor(
    injector: Injector,
    applicationCookieUserService: ApplicationCookieUserService
  ) {
    super(injector);
    super.setCookieUser(applicationCookieUserService.getCookieName());
  }

  notifyLanguageTranslate(): void {
    this.languageTranslateEmmiter.emit();
  }

  notifyLogout(): void {
    this.logoutEmmiter.emit();
  }
  
  notifyUpdateConsult(): void {
    this.updateConsultEmmiter.emit();
  }

  
}





import { Injectable } from '@angular/core';

import { SingleSignOnConfig } from 'iaudit-core-library';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SingleSignOnEnvironmentService {

constructor() { }

GetConfig(): SingleSignOnConfig {
  const config = new SingleSignOnConfig(
    environment.ApplicationId,
    environment.UrlConnect,
    null,
    environment.UrlLogout,
    environment.RedirectTo,
    environment.ResponseType,
    environment.Scope
  );
  return config;
}

}

import { Injectable, Injector } from '@angular/core';

import { BaseService } from 'iaudit-core-library';

import { ApplicationCookieUserService } from '../../shared/service/application-cookie-user.service';
import { IBehaviorClaim } from '../model/model';

@Injectable({
  providedIn: 'root'
})
export class BehaviorClaimService extends BaseService {
  constructor(
    inject: Injector,
    private applicationCookieUserService: ApplicationCookieUserService
  ) {
    super(inject);
    super.setCookieUser(applicationCookieUserService.getCookieName());
  }

  private init(): IBehaviorClaim {
    const claimsValue: IBehaviorClaim = {
      MenuConfiguration: false,
      MenuGetTicket: false,
      MenuChooseProfile: false,
      MenuBillingReport: false,
      MenuAnalysisedDocumentReport: false,
      MenuGetMetadata: false,
      MenuVote: false,
      MenuCustomer: false,
      Analyst: false,
      QueryHistory: false,
      MenuProspect: false,
      BgcProgress: false,
      ConsultBgc: false
    };
    return claimsValue;
  }

  private createInstance(base: IBehaviorClaim = null): IBehaviorClaim {
    const behaviorClaimResult: IBehaviorClaim = {
      MenuConfiguration: base == null ? false : base.MenuConfiguration,
      MenuGetTicket: base == null ? false : base.MenuGetTicket,
      MenuChooseProfile: base == null ? false : base.MenuChooseProfile,
      MenuBillingReport: base == null ? false : base.MenuBillingReport,
      MenuAnalysisedDocumentReport: base == null ? false : base.MenuAnalysisedDocumentReport,
      MenuGetMetadata: base == null ? false : base.MenuGetMetadata,
      MenuVote: base == null ? false : base.MenuVote,
      MenuCustomer: base == null ? false : base.MenuCustomer,
      Analyst: base == null ? false : base.Analyst,
      QueryHistory: base == null ? false : base.QueryHistory,
      MenuProspect: base == null ? false : base.MenuProspect,
      BgcProgress: base == null ? false : base.BgcProgress,
      ConsultBgc: base == null ? false : base.ConsultBgc
    };
    return behaviorClaimResult;
  }

  get(): IBehaviorClaim {
    const claims = this.applicationCookieUserService.getUserInfo().Claims;
    const filter = claims.filter(res => res.Type === 'BehaviorMenu');
    if (filter.length === 0) {
      return this.createInstance();
    }
    const claimValue = this.init();

    filter.forEach(claim => {
      switch (claim.Value) {
        case 'MenuConfiguration':
          claimValue.MenuConfiguration = true;
          break;
        case 'MenuGetTicket':
          claimValue.MenuGetTicket = true;
          break;
        case 'MenuChooseProfile':
          claimValue.MenuChooseProfile = true;
          break;
        case 'MenuBillingReport':
          claimValue.MenuBillingReport = true;
          break;
        case 'MenuAnalysisedDocumentReport':
          claimValue.MenuAnalysisedDocumentReport = true;
          break;
        case 'MenuGetMetadata':
          claimValue.MenuGetMetadata = true;
          break;
        case 'MenuVote':
          claimValue.MenuVote = true;
          break;
        case 'MenuCustomer':
          claimValue.MenuCustomer = true;
          break;
        case 'Analyst':
          claimValue.Analyst = true;
          break;
        case 'QueryHistory':
          claimValue.QueryHistory = true;
          break;
        case 'MenuProspect':
          claimValue.MenuProspect = true;
          break;
        case 'BgcProgress':
          claimValue.BgcProgress = true;
          break;
        case 'ConsultBgc':
          claimValue.ConsultBgc = true;
          break;
      }
    });
    const resultClaim = this.createInstance(claimValue);
    return resultClaim;

  }

}

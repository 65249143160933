import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { MasterLoginModule } from "./03 - Infrastructure/master-login/master-login.module";
import { MasterPageModule } from "./03 - Infrastructure/master-page/master-page.module";

import { MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "src/environments/environment";
import { MasterCustomLoginModule } from "./03 - Infrastructure/master-custom-login/master-custom-login.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { RequiredMaterialModule } from "./material.module";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { PaginatorIntlService } from "./01 - Presentation/shared/paginatorI18n/pagenatorI18n";

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MasterPageModule,
    RequiredMaterialModule,
    MasterLoginModule,
    MasterCustomLoginModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: "7f5de5fe-28fe-4165-8bd1-1f652629e2f9",
          authority:
            "https://login.microsoftonline.com/aa16af9a-7b74-4bac-beb9-c6e18694db76",
          redirectUri: environment.RedirectLogin,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ["user.read"],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
        ]),
      }
    ),
  ],
  providers: [TranslateStore,
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translate: TranslateService) => {
        return new PaginatorIntlService(translate).getPaginatorIntl();
      },
    },],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

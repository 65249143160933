<aside class="page-sidebar">
    <div class="page-logo">
        <a href="#" class="page-logo-link press-scale-down d-flex
            align-items-center position-relative" data-toggle="modal" data-target="#modal-shortcut">
            <img src="assets/dist/img/logo.png" alt="SmartAdmin WebApp" aria-roledescription="logo" />
            <span class="page-logo-text mr-1">SmartAdmin WebApp</span>
            <span class="position-absolute text-white opacity-50 small pos-top
                pos-right mr-2 mt-n2"></span>
            <i class="fal fa-angle-down d-inline-block ml-1 fs-lg
                color-primary-300"></i>
        </a>
    </div>
    <!-- BEGIN PRIMARY NAVIGATION -->
    <nav id="js-primary-nav" class="primary-nav" role="navigation" style="background-color: #1c2949">
        <div class="nav-filter">
            <div class="position-relative">
                <input type="text" id="nav_filter_input" placeholder="Filter
                    menu" class="form-control" tabindex="0" />
                <a href="#" onclick="return false;" class="btn-primary
                    btn-search-close js-waves-off" data-action="toggle" data-class="list-filter-active"
                    data-target=".page-sidebar">
                    <i class="fal fa-chevron-up"></i>
                </a>
            </div>
        </div>
        <div class="info-card">
            <img src="assets/dist/img/demo/avatars/avatar-admin.png" class="profile-image rounded-circle"
                alt="Dr. Codex Lantern" />
            <div class="info-card-text">
                <a href="#" class="d-flex align-items-center text-white">
                    <span class="text-truncate text-truncate-sm d-inline-block">
                        Dr. Codex Lantern
                    </span>
                </a>
                <span class="d-inline-block text-truncate text-truncate-sm">Toronto,
                    Canada</span>
            </div>
            <img src="assets/dist/img/card-backgrounds/cover-2-lg.png" class="cover" alt="cover" />
            <a href="#" onclick="return false;" class="pull-trigger-btn" data-action="toggle"
                data-class="list-filter-active" data-target=".page-sidebar" data-focus="nav_filter_input">
                <i class="fal fa-angle-down"></i>
            </a>
        </div>
        <ul id="js-nav-menu" class="nav-menu" style="background-color: #1c2949">
            <li *ngIf="personClaim.Create && personValidateClaim.Exists && behaviorProfile.ClientMaster">
                <a class="cursor" [routerLink]="['/create-ticket']"
                    title="{{ 'navigation.createTicket.hint' | translate }}" data-filter-tags="theme settings">
                    <i class="fal fa-cog"></i>
                    <span class="nav-link-text" data-i18n="nav.theme_settings">{{
                        "navigation.createTicket.label" | translate
                        }}</span>
                </a>
            </li>
            <li  *ngIf="ticketClaim.GetByDocumentExternal && behaviorProfile.ClientMaster">
                <a class="cursor" [routerLink]="['/consult-ticket']"
                    title="{{ 'navigation.consultTicket.hint' | translate }}" data-filter-tags="theme settings">
                    <i class="fal fa-cog"></i>
                    <span class="nav-link-text" data-i18n="nav.theme_settings">{{
                        "navigation.consultTicket.label" | translate
                        }}</span>
                </a>
            </li>
            <li *ngIf="behaviorProfile.ClientMaster">
                <a class="cursor" [routerLink]="['/consult-historical']"
                    title="{{ 'navigation.consultHistorical.hint' | translate }}" data-filter-tags="theme settings">
                    <i class="fal fa-cog"></i>
                    <span class="nav-link-text" data-i18n="nav.theme_settings">{{
                        "navigation.consultHistorical.label" | translate
                        }}</span>
                </a>
            </li>
            <li *ngIf="behaviorClaim.ConsultBgc && behaviorProfile.ClientUser">
                <a class="cursor" [routerLink]="['/consult-bgc']"
                    title="{{ 'navigation.consultBgc.hint' | translate }}" data-filter-tags="theme settings">
                    <i class="fal fa-cog"></i>
                    <span class="nav-link-text" data-i18n="nav.theme_settings">{{
                        "navigation.consultBgc.label" | translate
                        }}</span>
                </a>
            </li>
        </ul>
        <div class="filter-message js-filter-message bg-success-600"></div>
    </nav>
    <!-- END PRIMARY NAVIGATION -->
</aside>
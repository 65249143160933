import { Injectable } from '@angular/core';

import { CookieUserService, UserInfo } from 'iaudit-core-library';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationCookieUserService {
  private cookieName = environment.ApplicationCookieName;

  constructor(
    private cookieUserService: CookieUserService
  ) { }

  create(response: any): void {
    this.cookieUserService.create(this.cookieName, response);
  }

  delete(): void {
    this.cookieUserService.delete(this.cookieName);
  }

  get(): any {
    return this.cookieUserService.get(this.cookieName);
  }

  getUserInfo(): UserInfo {
    return this.cookieUserService.getUserInfo(this.cookieName);
  }

  getCookieName(): string {
    return this.cookieName;
  }
}

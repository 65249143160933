import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorIntlService extends MatPaginatorIntl {
  constructor(private readonly translate: TranslateService) {
    super();
  }
  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    const of = this.translate
      ? this.translate.instant('mat-paginator.of')
      : 'of';
    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  getPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();
    this.translate.onLangChange.subscribe(() => {
      paginatorIntl.itemsPerPageLabel = this.translate.instant(
        'mat-paginator.itemsPerPageLabel'
      );
      paginatorIntl.nextPageLabel = this.translate.instant(
        'mat-paginator.nextPageLabel'
      );
      paginatorIntl.previousPageLabel = this.translate.instant(
        'mat-paginator.previousPageLabel'
      );
      paginatorIntl.lastPageLabel = this.translate.instant(
        'mat-paginator.lastPageLabel'
      );
      paginatorIntl.firstPageLabel = this.translate.instant(
        'mat-paginator.firstPageLabel'
      );
      paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
    });
    return paginatorIntl;
  }
}

    <!-- BEGIN Page Wrapper -->
    <div class="page-wrapper">
      <div class="page-inner">
          <!-- BEGIN Left Aside -->
          <app-navigator></app-navigator>
          <!-- END Left Aside -->
          <div class="page-content-wrapper">
               <!-- BEGIN Page Header -->
             <app-header></app-header>
                <!-- END Page Header -->
              <!-- BEGIN Page Content -->
              <!-- the #js-page-content id is needed for some plugins to initialize -->
              <main id="js-page-content" role="main" class="page-content">
                <router-outlet></router-outlet>
              </main>
              <!-- this overlay is activated only when mobile menu is triggered -->
              <div class="page-content-overlay" data-action="toggle" data-class="mobile-nav-on"></div> <!-- END Page Content -->
              <!-- BEGIN Page Footer -->
              <!-- <app-footer></app-footer> -->
              <!-- END Page Footer -->
              <!-- BEGIN Shortcuts -->
              <div class="modal fade modal-backdrop-transparent" id="modal-shortcut" tabindex="-1" role="dialog" aria-labelledby="modal-shortcut" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-top modal-transparent" role="document">
                      <div class="modal-content">
                          <div class="modal-body">
                              <ul class="app-list w-auto h-auto p-0 text-left">
                                  <li>
                                      <a href="intel_introduction.html" class="app-list-item text-white border-0 m-0">
                                          <div class="icon-stack">
                                              <i class="base base-7 icon-stack-3x opacity-100 color-primary-500 "></i>
                                              <i class="base base-7 icon-stack-2x opacity-100 color-primary-300 "></i>
                                              <i class="fal fa-home icon-stack-1x opacity-100 color-white"></i>
                                          </div>
                                          <span class="app-list-name">
                                              Home
                                          </span>
                                      </a>
                                  </li>
                                  <li>
                                      <a href="page_inbox_general.html" class="app-list-item text-white border-0 m-0">
                                          <div class="icon-stack">
                                              <i class="base base-7 icon-stack-3x opacity-100 color-success-500 "></i>
                                              <i class="base base-7 icon-stack-2x opacity-100 color-success-300 "></i>
                                              <i class="ni ni-envelope icon-stack-1x text-white"></i>
                                          </div>
                                          <span class="app-list-name">
                                              Inbox
                                          </span>
                                      </a>
                                  </li>
                                  <li>
                                      <a href="intel_introduction.html" class="app-list-item text-white border-0 m-0">
                                          <div class="icon-stack">
                                              <i class="base base-7 icon-stack-2x opacity-100 color-primary-300 "></i>
                                              <i class="fal fa-plus icon-stack-1x opacity-100 color-white"></i>
                                          </div>
                                          <span class="app-list-name">
                                              Add More
                                          </span>
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- END Page Wrapper -->
  <!-- BEGIN Quick Menu -->
  <app-quick-menu></app-quick-menu>
  <!-- END Quick Menu -->

  <app-footer></app-footer>


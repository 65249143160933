<div class="d-flex w-100 page-login">

  <div class="login-left w-50">
    <div class="login-content">
      <div class="row text-center" style="margin-bottom:15%">
        <img src="../../../assets/iaudit/img/credential/tecnologia.png" alt="iAudit Sistemas">
      </div>
      <div class="row w-100 text-center">
        <p style="text-align:center;width:100%">
          <span>Apelação - Manager Client</span>
        </p>

      </div>
      <div class="row w-100 text-center">
        <p style="text-align:center;width:100%;text-size-adjust:auto;font-size:medium">
          Para continuar realize a autenticação ao lado.
        </p>
      </div>
    </div>
  </div>
<div class="w-50">
  <router-outlet></router-outlet>
</div>


</div>

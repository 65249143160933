export const environment = {
  production: true,
  ApelacaoApiUrl: "https://ma001-appeal-dev.iauditcloud.com.br/api/",
  CustomerApiUrl: "https://ma001-customer-dev.iauditcloud.com.br/api/",
  LanguageApiUrl: 'https://ma001-language-dev.iauditcloud.com.br/api/',
  AuthenticationApiUrl: "https://ma001-auth2-dev.iauditcloud.com.br/api/",
  ApplicationId: "d36a2ba0-ed19-4b64-d0dd-08d96e3da2b8",
  MsCustomerApplicationId: "8aba3ce3-f823-425b-e0c5-08d97c4b97f5",
  MsLanguageApplicationId: "a95b75ed-dbf6-4323-1faa-08db05658273",
  UrlConnect: "https://auth-dev.iauditcloud.com.br",
  UrlLogout: "https://appealcustomermanager-dev.iauditcloud.com.br",
  RedirectLogin:
    "https://appealcustomermanager-dev.iauditcloud.com.br/login/get-token",
  RedirectTo:
    "https://appealcustomermanager-dev.iauditcloud.com.br/auth/signin-oicd",
  ResponseType: "code",
  Scope: "openid profile",
  ApplicationCookieName: "appeal-manager-client",
  BlobUrlToken: '?st=2024-07-01T17:35:58Z&se=2028-07-02T01:35:58Z&si=dev-uploads&spr=https&sv=2022-11-02&sr=c&sig=Cpmb8rAnpR3bBdeZoyNBCHOUG4IzvrUadw4Lrdbl%2BG0%3D'
};

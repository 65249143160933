import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";

import { BaseService } from "iaudit-core-library";

import { map } from "rxjs/operators";
import { ApplicationCookieUserService } from "../../shared/service/application-cookie-user.service";
import { UrlService } from "../../shared/service/url.service";
import { ILanguageResponse } from "../model/model";

@Injectable({
  providedIn: "root",
})
export class LanguageService extends BaseService {
  constructor(
    injector: Injector,
    applicationCookieUserService: ApplicationCookieUserService,
    private urlService: UrlService
  ) {
    super(injector);
    super.setCookieUser(applicationCookieUserService.getCookieName());
  }

  get(): Observable<ILanguageResponse[]> {
    return this.httpClient
      .get<ILanguageResponse[]>(
        this.urlService.getLanguageApiUrl() + "v1/" + `language`,
        this.getAuthHeaderJson()
      )
      .pipe(map(this.extractData));
  }
}

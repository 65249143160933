import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { RequiredModule } from 'iaudit-core-library';
import { MasterCustomLoginComponent } from './master-custom-login.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    RequiredModule
  ],
  declarations: [
    MasterCustomLoginComponent
  ],
  exports: [
    MasterCustomLoginComponent
  ]
})
export class MasterCustomLoginModule { }

import { ClaimTicketService } from "./../../../02 - Domain/claim/service/claim-ticket.service";
import { Component, Injector, OnInit } from "@angular/core";

import { BaseComponent } from "iaudit-core-library";

import {
  IBehaviorClaim,
  IPersonClaim,
  IPersonValidateClaim,
  ITicketClaim,
} from "src/app/02 - Domain/claim/model/model";
import { ClaimPersonValidateService } from "./../../../02 - Domain/claim/service/claim-person-validate.service";
import { ClaimPersonService } from "./../../../02 - Domain/claim/service/claim-person.service";
import { BehaviorClaimService } from "src/app/02 - Domain/claim/service/behavior-claim.service";
import { IBehaviorProfile } from "src/app/02 - Domain/shared/claims/behavior-profile/model/model";
import { BehaviorProfileService } from "src/app/02 - Domain/shared/claims/behavior-profile/service/behavior-profile.service";

@Component({
  selector: "app-navigator",
  templateUrl: "./navigator.component.html",
  styleUrls: ["./navigator.component.scss"],
})
export class NavigatorComponent extends BaseComponent implements OnInit {
  personValidateClaim: IPersonValidateClaim;
  personClaim: IPersonClaim;
  ticketClaim: ITicketClaim;
  behaviorClaim: IBehaviorClaim;
  behaviorProfile: IBehaviorProfile;
  constructor(
    inject: Injector,
    private behaviorClaimService: BehaviorClaimService,
    private behaviorProfileService: BehaviorProfileService,
    private claimPersonValidateService: ClaimPersonValidateService,
    private claimPersonService: ClaimPersonService,
    private claimTicketService: ClaimTicketService
  ) {
    super(inject);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.personClaim = this.claimPersonService.get();
    this.personValidateClaim = this.claimPersonValidateService.get();
    this.ticketClaim = this.claimTicketService.get();
    this.behaviorClaim = this.behaviorClaimService.get();
    this.behaviorProfile = this.behaviorProfileService.get();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { RequiredModule } from 'iaudit-core-library';

import { MasterLoginComponent } from './master-login.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    RequiredModule
  ],
  exports: [
    MasterLoginComponent
  ],
  declarations: [MasterLoginComponent]
})
export class MasterLoginModule { }

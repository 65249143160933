import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { AuthGuardService } from './02 - Domain/auth/service/auth-guard.service';
import { MasterCustomLoginComponent } from './03 - Infrastructure/master-custom-login/master-custom-login.component';
import { MasterLoginComponent } from './03 - Infrastructure/master-login/master-login.component';
import { MasterPageComponent } from './03 - Infrastructure/master-page/master-page.component';
const isIframe = window !== window.parent && !window.opener;
const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [{
      path: '',
      loadChildren: () => import('./01 - Presentation/home/home.module').then(m => m.HomeModule)
    }],
    canActivate: [AuthGuardService]
  },
  {
    path: 'home',
    component: MasterPageComponent,
    children: [{
      path: '',
      loadChildren: () => import('./01 - Presentation/home/home.module').then(m => m.HomeModule)
    }],
    canActivate: [AuthGuardService]
  },
  {
    path: 'auth',
    component: MasterLoginComponent,
    children: [{
      path: '',
      loadChildren: () => import('./01 - Presentation/auth/auth.module').then(m => m.AuthModule)
    }],
  },
  {
    path: 'create-ticket',
    component: MasterPageComponent,
    children: [{
      path: '',
      loadChildren: () => import('./01 - Presentation/create-ticket/create-ticket.module').then(m => m.CreateTicketModule)
    }],
  },
  {
    path: 'consult-ticket',
    component: MasterPageComponent,
    children: [{
      path: '',
      loadChildren: () => import('./01 - Presentation/consult-ticket/consult-ticket.module').then(m => m.ConsultTicketModule)
    }],
  },
  {
    path: 'consult-historical',
    component: MasterPageComponent,
    children: [{
      path: '',
      loadChildren: () => import('./01 - Presentation/consult-historical/consult-historical.module').then(m => m.ConsultHistoricalModule)
    }],
  },
  {
    path: 'consult-bgc',
    component: MasterPageComponent,
    children: [{
      path: '',
      loadChildren: () => import('./01 - Presentation/consult-bgc/consult-bgc.module').then(m => m.ConsultBgcModule)
    }],
  },
  {
    path: 'login/get-token',
    component: MasterCustomLoginComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./01 - Presentation/auth/custom/custom.module').then(
            (m) => m.CustomModule
          ),
      },
    ],
    canActivate: [MsalGuard],
  },
  {
    path: 'auth',
    component: MasterLoginComponent,
    children: [{
      path: '',
      loadChildren: () => import('./01 - Presentation/auth/auth.module').then(m => m.AuthModule)
    }]
  },
  {
    path: 'auth/custom',
    component: MasterCustomLoginComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./01 - Presentation/auth/custom/custom.module').then(
            (m) => m.CustomModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

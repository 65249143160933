import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { RequiredModule } from 'iaudit-core-library';

import { MasterPageComponent } from './master-page.component';
import { NavigatorComponent } from './navigator/navigator.component';
import { HeaderComponent } from './header/header.component';
import { QuickMenuComponent } from './quick-menu/quick-menu.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  imports: [
    RouterModule,
    RequiredModule,
    RouterModule
  ],
  declarations: [
    MasterPageComponent,
    NavigatorComponent,
    HeaderComponent,
    QuickMenuComponent,
    FooterComponent
  ],
  exports: [
    MasterPageComponent
  ]
})
export class MasterPageModule { }

import { Injectable, Injector } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { AuthenticationService, BaseService } from 'iaudit-core-library';

import { ApplicationCookieUserService } from '../../shared/service/application-cookie-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService extends BaseService implements CanActivate {

  constructor(
    injector: Injector,
    private authenticationService: AuthenticationService,
    applicationCookieUserService: ApplicationCookieUserService,
    private router: Router
  ) {
    super(injector);
    super.setCookieUser(applicationCookieUserService.getCookieName());
    this.authenticationService.setCookieUser(applicationCookieUserService.getCookieName());
  }

  canActivate(): boolean | UrlTree {
    try {

      const value = this.authenticationService.isAuthenticated();
      if (!value) {
        return this.router.parseUrl('/auth/login');
      }
      return value;
    }
    catch (e) {
      this.authenticationService.logout();
      return this.router.parseUrl('/auth/login');
    }
  }
}
